import type { InitOptions } from 'i18next'

export const supportedLngs = ['en', 'de', 'es', 'it', 'cs', 'fr']
export const fallbackLng = 'en'

export const languageNames: Record<string, string> = {
  en: 'English',
  de: 'Deutsch',
  es: 'Español',
  it: 'Italiano',
  cs: 'Čeština',
  fr: 'Français',
}

export const i18nConfig = {
  supportedLngs,
  fallbackLng: false,
  defaultNS: 'common',
  react: { useSuspense: true },
  nsSeparator: '::',
  returnNull: false,
  returnEmptyString: false,
  returnObjects: false,
  parseMissingKeyHandler(key: string): any {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.info(`Missing translation for key: ${key}`)
    }
    return key
  },
} satisfies InitOptions
